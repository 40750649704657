<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          outlined
          class="col-2"
          dense
          v-model="FilterString"
          label="患者名称"
          color="primary"
        />
        <q-input
          dense
          outlined
          type="date"
          v-model="BegDate"
          label="查询开始日期"
          stack-label
        />
        <q-input
          dense
          outlined
          type="date"
          v-model="EndDate"
          label="查询结束日期"
          stack-label
        />
        <q-btn
          color="primary"
          icon="search"
          @click="onLoadData"
          :loading="PdmBodycheckYuyueListLoading"
          style="height: 38px;"
        >
          查询
        </q-btn>
        <q-space />
        <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckYuyueListLoading" @click="onExportData">导出</q-btn> -->
        <!-- <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmBodycheckYuyueListLoading"
          @click="onAdd"
          >新增</q-btn
        > -->
      </div>
      <vxe-grid
        v-bind="gridOptions"
        :sort-config="{ multiple: true }"
        :custom-config="{ storage: true }"
        id="custom-config"
        :export-config="gridOptions.tableExport"
        :height="$store.getters.maxPageHeight - 140"
        :loading="PdmBodycheckYuyueListLoading"
        highlight-current-row
        size="mini"
      >
        <template #toolbar_buttons id="custom-config"> </template>
        <template #operate="{ row }">
          <vxe-button
            flat
            color="primary"
            icon="fas  fa-edit"
            @click="onEdit(row)"
            >编辑</vxe-button
          >
          <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          >
        </template>
      </vxe-grid>
      <vxe-pager
        perfect
        size="mini"
        :page-sizes="tablePage.pageSizes"
        v-model:current-page="tablePage.currentPage"
        v-model:page-size="tablePage.pageSize"
        :total="tablePage.totalResult"
        :layouts="[
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Sizes',
          'Total',
        ]"
        @page-change="handlePageChange"
      >
      </vxe-pager>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import * as BodycheckYuyue from "@/api/pdm/bodycheckYuyue";

export default {
  components: {},
  computed: {
    ...mapState("PdmBodycheckYuyue", [
      "PdmBodycheckYuyueList",
      "PdmBodycheckYuyueListLoading",
      "PdmBodycheckYuyueEntity",
      "PdmBodycheckYuyueShowDlg",
    ]),

    pdmBodycheckYuyueshowDlg: {
      get() {
        return this.PdmBodycheckYuyueShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit(
          "PdmBodycheckYuyue/setPdmBodycheckYuyueShowDlg",
          val
        );
      },
    },
  },
  data() {
    return {
      BegDate: XEUtils.toDateString(XEUtils.getWhatMonth(new Date(), -1), "yyyy-MM-dd"),
      EndDate: XEUtils.toDateString(new Date(), "yyyy-MM-dd"),
      FilterString: "",
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          // {
          //   field: "HospID",
          //   title: "机构ID",
          //   sortable: true,
          //   align: "center",
          //   width: 160,
          // },
         
          {
            field: "TaoCan_Name",
            title: "预约的套餐",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "YuYue_Name",
            title: "患者名称",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "YuYue_CertNo",
            title: "身份证号",
            sortable: true,
            align: "center",
            width: 160,
          },
           {
            field: "YuYue_Phone",
            title: "联系电话",
            sortable: true,
            align: "center",
            width: 140,
          },
          {
            field: "PAY_REALAMT",
            title: "支付金额",
            sortable: true,
            align: "right",
            width: 100,
          },
          {
            field: "PayStateStr",
            title: "支付状态",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "YuYue_Date",
            title: "预约日期",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "AddDate",
            title: "添加日期",
            sortable: true,
            align: "center",
            width: 160,
          },
          // {
          //   title: "操作",
          //   width: 200,
          //   slots: { default: "operate" },
          //   align: "center",
          //   fixed: "right",
          // },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmBodycheckYuyueList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
  },
  methods: {
    ...mapActions("PdmBodycheckYuyue", [
      "actGetPdmBodycheckYuyueListByPage",
      "actCreatePdmBodycheckYuyue",
      "actEditPdmBodycheckYuyueData",
      "actEditPdmBodycheckYuyue",
      "actDeletePdmBodycheckYuyue",
    ]),
    onLoadData() {
      BodycheckYuyue.GetYuYueList({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        BegDate:this.BegDate,
        EndDate:this.EndDate,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          if (res.Code == 0) {
            this.gridOptions.data = res.Data;
            this.tablePage.totalResult = res.Count;
          } else {
            dlg.alert(res.Message);
          }
        })
        .finally(() => {
          this.$store.commit(
            "PdmBodycheckYuyue/setPdmBodycheckYuyueListLoading",
            false
          );
          //this.PdmBodycheckYuyueListLoading = false;
        });
    },
    onAdd() {
      this.actCreatePdmBodycheckYuyue({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
      });
    },
    onEdit(e) {
      var obj = {};
      Object.assign(obj, e);
      this.actEditPdmBodycheckYuyue(obj);
    },
    onDelete(e) {
      this.actDeletePdmBodycheckYuyue({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
