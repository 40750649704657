/*------------------------------------------------
// File Name:PDM_BodyCheck_YuYue.js
// File Description:PDM_BodyCheck_YuYue API方法js接口
// Author:zxl
// Create Time:2023/05/26 18:22:35
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _bodycheckYuyue: "/PDM_BodyCheck_YuYue"
}

export default api


//-------------------创建对象-------------------
export function CreatePDM_BodyCheck_YuYueEntity(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/CreatePDM_BodyCheck_YuYueEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取列表-------------------
export function GetPDM_BodyCheck_YuYueList(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/GetPDM_BodyCheck_YuYueList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取列表---------------
export function GetPDM_BodyCheck_YuYueListByPage(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/GetPDM_BodyCheck_YuYueListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取对象---------------
export function GetPDM_BodyCheck_YuYueEntity(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/GetPDM_BodyCheck_YuYueEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存对象---------------
export function UpdatePDM_BodyCheck_YuYueEntity(parameter) {
    return axios({
        url: api._bodycheckYuyue+ "/UpdatePDM_BodyCheck_YuYueEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉对象---------------
export function DeletePDM_BodyCheck_YuYueEntity(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/DeletePDM_BodyCheck_YuYueEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------预约统计--导入数据专用---------------
export function GetYuYueTongJi(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/GetYuYueTongJi",
        method: 'post',
        data: parameter
    })
}

//-------------------预约记录---------------
export function GetYuYueList(parameter) {
    return axios({
        url: api._bodycheckYuyue + "/GetYuYueList",
        method: 'post',
        data: parameter
    })
}



